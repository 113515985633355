(function(window) {

	var EyeQTheme = function() {

		var enableDynamicFormElements = function() {
			$('.form-group.-error .form-control').on('focus', function() {
				$(this).parent().parent().removeClass('-error');
			});
			$('.form-group .form-control').on('focus', function() {
				$(this).parent().parent().addClass('active');
			});
			$('.form-group .form-control').on('blur', function() {
				if ($(this).val() === '') {
					$(this).parent().parent().removeClass('active');
				}
			});
			
			$('.form-group .form-control').each(function() {
				if ($(this).val() !== '') {
					$(this).parent().parent().addClass('active');
				}
			});
		};
		
		var enableMeetOurDoctorButtonSlideEffect = function() {
			$('.nav-link-slide-to-idhash').on('click', function() {
				var target = (this).href;
				target = $('#'+target.substr(target.indexOf('#')+1));
				target.focus();
				$([document.documentElement, document.body]).animate({
					scrollTop: target.offset().top-70
				}, 700);
			});
		};				
		
		var enableHomePageFunctionality = function(){
			$( ".card-img-top" ).css({filter: ('brightness(0.4)')});
			
			$('.service-section-thumb').click(function() {
				window.location.href = 'services#'+$(this).attr("class").split(" ")[2];
			});	

			$( ".card-img-overlay" ).mouseenter(function(){
				$(this).prev().css({filter: ('brightness(0.3)')});
			});

			$( ".card-img-overlay" ).mouseleave(function(){
				$(this).prev().css({filter: ('brightness(0.4)')});
			});

			$( ".card-img-top" ).mouseenter(function(){
				$(this).css({filter: ('brightness(0.3)')});
			});

			$( ".card-img-top" ).mouseleave(function(){
				$(this).css({filter: ('brightness(0.4)')});
			});
			
			$('.btn-appointment').click(function () {
				window.open("https://eyeqeyecare.mypatientportal.xyz/dashboard","_blank");
			});			
		};
		
		
		var enableBrandSlider = function(){
			let items = document.querySelectorAll('.carousel .carousel-item')

			items.forEach((el) => {
				const minPerSlide = 4;
				let next = el.nextElementSibling;
				for (var i=1; i<minPerSlide; i++) {
					if (!next) {
						// wrap carousel by using first child
						next = items[0];
					}
					let cloneChild = next.cloneNode(true);
					el.appendChild(cloneChild.children[0]);
					next = next.nextElementSibling;
				}
			});	
		};

		var enableImageResposive = function(){
			$(window).resize(function () {
				var imageList = $('.eye-q-imgs-responsive');
				var imageSource, imageName, imagePath = "";
				if ($(window).width() >= 1200) {
					for (i = 0; i < imageList.length; i++) {
						imageSource = imageList[i].src;
						if (imageSource.includes('/assets/images/mobile_')) {
							imageName = imageSource.substr(imageSource.indexOf('images/mobile_') + 14, imageSource.length - 1);
							imagePath = imageSource.substr(0, imageSource.indexOf('images/') + 7);
							imageList[i].src = imagePath + imageName;
						}
					}
				} else {
					for (i = 0; i < imageList.length; i++) {
						imageSource = imageList[i].src;
						if (imageSource.includes('/assets/images/mobile_')) {

						} else {
							imageName = imageSource.substr(imageSource.indexOf('images/') + 7, imageSource.length - 1);
							imagePath = imageSource.substr(0, imageSource.indexOf('images/') + 7);
							imageList[i].src = imagePath + 'mobile_' + imageName;
						}
					}
				}
			});			
		};

		this.enableDynamicFormElements = enableDynamicFormElements;
		this.enableBrandSlider  = enableBrandSlider;
		this.enableHomePageFunctionality = enableHomePageFunctionality;
		this.enableImageResposive = enableImageResposive;
		this.enableMeetOurDoctorButtonSlideEffect = enableMeetOurDoctorButtonSlideEffect;

	};

	/* -------------------------------------------------------------------------- */

	$(document).ready(function() {
		var eyeQTheme = new EyeQTheme();
		eyeQTheme.enableDynamicFormElements();
		eyeQTheme.enableHomePageFunctionality();
		eyeQTheme.enableBrandSlider();
		eyeQTheme.enableImageResposive();
		eyeQTheme.enableMeetOurDoctorButtonSlideEffect();
		
		window.eyeQTheme = eyeQTheme;
		
		$(window).trigger('resize');
		$(window).trigger('scroll');
	});

})(window);